.pointer{
  cursor: pointer;
}
/*path, polyline {
	 stroke-width: inherit !important;
	fill: inherit !important;
	stroke: none !important;
	all: revert-layer !important; 
}*/
.apexcharts-series path, .apexcharts-series polyline{
	stroke-width: 2px !important;
}