

#rect {
    animation: slideOver 3s linear infinite;
}


#path1 {
    fill: #EBCE75 !important;
    animation: slideOver 3s linear infinite;
    stroke: black !important;

}

#path1 {
    stroke-width: 0 !important;
    animation: logo 3s linear infinite;
}
#path2 {
    fill: #319a7e !important;
    animation: logo 3s linear infinite;
}




@keyframes logo {
    0% {
        stroke-width: 1pt !important;
        stroke-dashoffset: 1200;
        fill-opacity: 0;
    }
    40% {
        fill-opacity: 0;
    }
    100% {
        stroke-dashoffset: 0;
        fill-opacity: 1;
        stroke-width: 1pt;
    }
}


.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #6FD1B0 #6FD1B0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: 32px;
    height: 32px;

    border-color: #1F1C36 #1F1C36 transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
      